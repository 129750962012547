// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-enterprise-js": () => import("./../../src/templates/enterprise.js" /* webpackChunkName: "component---src-templates-enterprise-js" */),
  "component---src-templates-fbads-enterprise-js": () => import("./../../src/templates/fbads-enterprise.js" /* webpackChunkName: "component---src-templates-fbads-enterprise-js" */),
  "component---src-templates-fbads-integrations-js": () => import("./../../src/templates/fbads-integrations.js" /* webpackChunkName: "component---src-templates-fbads-integrations-js" */),
  "component---src-templates-fbads-products-js": () => import("./../../src/templates/fbads-products.js" /* webpackChunkName: "component---src-templates-fbads-products-js" */),
  "component---src-templates-ga-enterprise-js": () => import("./../../src/templates/ga-enterprise.js" /* webpackChunkName: "component---src-templates-ga-enterprise-js" */),
  "component---src-templates-ga-integrations-js": () => import("./../../src/templates/ga-integrations.js" /* webpackChunkName: "component---src-templates-ga-integrations-js" */),
  "component---src-templates-ga-products-js": () => import("./../../src/templates/ga-products.js" /* webpackChunkName: "component---src-templates-ga-products-js" */),
  "component---src-templates-generic-js": () => import("./../../src/templates/generic.js" /* webpackChunkName: "component---src-templates-generic-js" */),
  "component---src-templates-index-js": () => import("./../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-integrations-js": () => import("./../../src/templates/integrations.js" /* webpackChunkName: "component---src-templates-integrations-js" */),
  "component---src-templates-jira-enterprise-js": () => import("./../../src/templates/jira-enterprise.js" /* webpackChunkName: "component---src-templates-jira-enterprise-js" */),
  "component---src-templates-jira-integrations-js": () => import("./../../src/templates/jira-integrations.js" /* webpackChunkName: "component---src-templates-jira-integrations-js" */),
  "component---src-templates-jira-landing-js": () => import("./../../src/templates/jira-landing.js" /* webpackChunkName: "component---src-templates-jira-landing-js" */),
  "component---src-templates-jira-products-js": () => import("./../../src/templates/jira-products.js" /* webpackChunkName: "component---src-templates-jira-products-js" */),
  "component---src-templates-products-js": () => import("./../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */)
}

